import { MouseEventHandler } from 'react';
import { SC } from '../../styles/theme';
import { IconStyled } from './IconStyled';
import PoiCommercialBuilding from "@components/Icon/AC/icons/poiCommercialBuilding.svg";

export enum Icons {
    Account,
    Alert,
    AlertOutlined,
    Basket,
    Calendar,
    Calculator,
    CalculatorDS,
    Car,
    Clock,
    ClockSolid,
    ChevronUp,
    Profile,
    ProfileCall,
    ChevronDown,
    ChevronRight,
    ChevronRightThin,
    SecurePayment,
    CarStore,
    Emissions,
    Fuel,
    Trash,
    Manual,
    Engine,
    Drive,
    Request,
    Interior,
    Exterior,
    Extras,
    Download,
    Cart,
    Logo,
    Headset,
    HeadsetV2,
    Question,
    LogoMobile,
    Info,
    InfoCircle,
    Plus,
    Correct,
    Minus,
    Phone,
    PhoneV2,
    ArrowUp,
    InfoAlternative,
    Customize,
    ZoomIn,
    SquarePlus,
    Cancel,
    RoundInfo,
    EmissionA,
    EngineSimple,
    Menu,
    OffreMoment,
    LogoSmall,
    MyAccount,
    MapPoint,
    Check,
    Target,
    Settings,
    ClockV2,
    Mail,
    Iphone,
    StoreCar,
    StoreCarSolid,
    Search,
    Logout,
    Information,
    Dealer,
    chevronLeft,
    chevronLeftV2,
    edit,
    chevronLeftOutlined,
    chevronRightOutlined,
    clock,
    ClockBlack,
    ReinsuranceCalendar,
    ShareConfigurationModalIcon,
    InteriorV2,
    ExteriorV2,
    InteriorBold,
    ExteriorBold,
    EmailTypeTwo,
    MapPointTypeTwo,
    IphoneTypeTwo,
    Dash,
    PlusIcon,
    PoiCommercialBuilding,
    LoadingSuccess,
    Folder,
}

export interface IconProps extends SC {
    width?: number;
    height?: number;
    auto?: boolean;
    name: Icons;
    color?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
    dataTestId?: string;
    altText?: string;
    altId?: string;
    role?: string;
    ref?: React.Ref<any>;
}

export const Icon = IconStyled;
export default Icon;
