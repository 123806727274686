import i18n from 'i18next';
import { BannerJourneyType } from '../redux/content/content.duck.interface';
import { B2B_FIELD } from './enums';

// webpack will replace process.env.ENV_VAR in build time if ENV_VAR is set to some value
// this mainly just saves space by shaving off unused envs but causes problems with runtime load of environments by MM
// so environment local var can be used to prevent that, with small cost to size
const environment = JSON.parse(JSON.stringify(process.env));

export type BRAND_TYPE = 'AC' | 'AP' | 'DS' | 'OV' | undefined;
export const BRAND_AP: BRAND_TYPE = 'AP';
export const BRAND_AC: BRAND_TYPE = 'AC';
export const BRAND_DS: BRAND_TYPE = 'DS';
export const BRAND_OV: BRAND_TYPE = 'OV';
export const BRAND: BRAND_TYPE = (environment.NEXT_PUBLIC_BRAND_CODE || environment.NEXT_PUBLIC_BRAND) as BRAND_TYPE;
export const isBrand = (type: BRAND_TYPE): boolean => BRAND === type;
export const isBrandAP = isBrand(BRAND_AP);
export const isBrandAC = isBrand(BRAND_AC);
export const isBrandDS = isBrand(BRAND_DS);
export const isBrandOV = isBrand(BRAND_OV);

export const PRINT_DATEFORMAT_ERRORS = environment.NPM_CONFIG_DATELOG || false;
export const PRINT_MEMORY_USAGE = environment.NPM_CONFIG_MEMLOG || false;
export const PRINT_PX_ERROR = environment.NPM_CONFIG_PXLOG || false;

export const STATIC_ASSET_BASE_URL = environment.STATIC_ASSET_BASE_URL || '/public';
export const MARKET = environment.NEXT_PUBLIC_MARKET;

export const LANGUAGE = environment.NEXT_PUBLIC_LANGUAGE;

export const IS_CLIENT = typeof window !== 'undefined';
export const IS_SERVER = !IS_CLIENT;
export const IS_DEV = process.env.NODE_ENV === 'development';
export const BACKEND_INTERNAL_URL = `${environment.NEXT_SERVER_INTERNAL_SPC_API_URL}/api/v1`;
export const BACKEND_PUBLIC_URL = `${environment.NEXT_PUBLIC_API_URL}/spc-api/api/v1`;

export const BACKEND_URL = IS_SERVER ? BACKEND_INTERNAL_URL : BACKEND_PUBLIC_URL;

export const BACKEND_DEEPLINK_REDIRECT_URL = `${environment.NEXT_PUBLIC_API_URL}/spc-api/redirect`;

export const CDN_HOST_URL = environment.NEXT_PUBLIC_CDN_HOST;
export const CDN_ENABLED = environment.NEXT_PUBLIC_CDN_ENABLED;

export const SESSION_COOKIE_NAME = environment.NEXT_PUBLIC_SESSION_COOKIE_NAME;
export const SESSION_COOKIE_NAME_EXPERIMENTS = `${SESSION_COOKIE_NAME}_experiments`;
export const SESSION_DOMAIN = environment.NEXT_PUBLIC_SESSION_DOMAIN;
export const SESSION_LIFETIME = environment.NEXT_PUBLIC_SESSION_LIFETIME;
export const SESSION_REQUEST_HEADER_NAME = environment.NEXT_PUBLIC_SESSION_REQUEST_HEADER_NAME;

export const PX_BACKEND_URL = environment.NEXT_PUBLIC_PX_BACKEND_URL;

export const MY_ACCOUNT_URL = environment.NEXT_PUBLIC_MY_ACCOUNT_URL;

export const FINANCE_SIMULATION_BASE_URL = environment.NEXT_PUBLIC_FINANCE_SIMULATION_BASE_URL;
export const FINANCE_SIMULATION_CLIENT_ID = environment.NEXT_PUBLIC_FINANCE_SIMULATION_CLIENT_ID;

export const FINANCE_SIMULATION_V2_BASE_URL = environment.NEXT_PUBLIC_FINANCE_SIMULATION_V2_BASE_URL;
export const FINANCE_SIMULATION_V2_CLIENT_ID = environment.NEXT_PUBLIC_FINANCE_SIMULATION_V2_CLIENT_ID;

export const MOP_ID_COOKIE_NAME = 'MOP_ID';
export const MOP_CAR_CONFIGURATION_COOKIE_NAME = 'MOP_CAR_CONFIGURATION';
export const MOP_JOURNEY_COOKIE_NAME = 'MOP_JOURNEY';

export const DIGITAL_URL = environment.NEXT_PUBLIC_DIGITAL_URL;
export const LEAD_FORM_ID = environment.NEXT_PUBLIC_LEAD_FORM_ID;
export const BASKET_LEAD_CAPTURE_FORM_ID = environment.NEXT_PUBLIC_SHARE_BASKET_LEAD_CAPTURE_FORM_ID;

export const DEFAULT_BANNER_JOURNEY_TYPE = environment.NEXT_PUBLIC_DEFAULT_BANNER_JOURNEY_TYPE as BannerJourneyType;

export const NEXT_PUBLIC_API_URL = environment.NEXT_PUBLIC_API_URL;
export const NEXT_PUBLIC_APP_PREFIX = environment.NEXT_PUBLIC_APP_PREFIX;

export const NEXT_PUBLIC_POSTHOG_API_KEY = environment.NEXT_PUBLIC_POSTHOG_API_KEY;
export const NEXT_PUBLIC_POSTHOG_DOMAIN = environment.NEXT_PUBLIC_POSTHOG_DOMAIN;

export const DYNAMIC_YIELD_API_TOKEN = environment.NEXT_PUBLIC_DYNAMIC_YIELD_API_TOKEN;

export const PLAUSIBLE_HEAD_SCRIPT_ENABLED = environment.NEXT_PUBLIC_PLAUSIBLE_HEAD_SCRIPT_ENABLED;
export const PLAUSIBLE_HEAD_SCRIPT_DATA_DOMAIN = environment.NEXT_PUBLIC_PLAUSIBLE_HEAD_SCRIPT_DATA_DOMAIN;

export const SPLUNK_HEAD_SCRIPT_ENABLED = environment.NEXT_PUBLIC_SPLUNK_HEAD_SCRIPT_ENABLED;

export const FAQ_URL_STRING = 'faqs';
export const STOCK_TRIMS_PER_PAGE = 9;

export const CARTE_GRISE_FEE = 'carte_grise';
export const MALUS_FEE = 'malus';
export const REGISTRATION_FEE = 'registration_fee';

export const CHECKOUT_CASH_URL = environment.NEXT_PUBLIC_CHECKOUT_URL_CASH;
export const CHECKOUT_FINANCE_URL = environment.NEXT_PUBLIC_CHECKOUT_URL_FINANCE;

export const FINANCE_WIDGET_CONTEXT_COLOUR = environment.NEXT_PUBLIC_FINANCE_WIDGET_CONTEXT_COLOUR;
export const FINANCE_DETAIL_TOKEN = 'finance_detail_text_token';

export enum ROLES {
    IMG = 'img',
    BUTTON = 'button',
    LINK = 'link',
    LOGO = 'logo',
}

export enum PAGE_VERSIONS {
    V1 = 'v1',
    V2 = 'v2',
}

export enum MARKETS {
    FR = 'fr',
    GB = 'gb',
    ES = 'es',
    IT = 'it',
    DE = 'de',
    BE = 'be',
    PT = 'pt',
}

export enum LANGUAGES {
    FR = 'fr',
    EN = 'en',
    ES = 'es',
    IT = 'it',
    DE = 'de',
    NL = 'nl',
    PT = 'pt',
}

export enum BASKET_STEPS {
    DEFAULT = -1,
    PAYMENT = 1,
    PX = 2,
    FEES = 3,
    SCRAPPGE = 4,
}

export const civilities = {
    fr: ['M.', 'Mme'],
    en: ['Mrs.', 'Miss.', 'Mr.', 'Ms'],
    es: ['Sra', 'Sr'],
    pt: ['Sra', 'Sr'], // TODO
    it: ['Signora', 'Signorina', 'Signor'],
    de: ['M.', 'Mme'],
    nl: ['Mevr.', 'Dhr.'],
};

export enum CUSTOM_PX_VALUES {
    YES = 'has_active',
    NO = 'no_active',
}

export enum REGISTRATION_FEES_VALUES {
    YES = 'has_registration_fee',
    NO = 'no_registration_fee',
}

export enum SCRAPPAGE_VALUES {
    YES = 'with_scrappage',
    NO = 'without_scrappage',
}

export enum DATE_FORMAT {
    FR = 'dd/MM/yyyy',
    EN = 'dd-MM-yyyy',
}

export enum FUEL_TYPES {
    DIESEL = 'diesel;turbo diesel;diésel;diesel hdi',
    HYBRID = 'hybride-essence;petrol-hybrid;híbrido-enchufable;plug-in hybrid;phev-benzin;hybrid / benzin;híbrido gasolina;hybride;Híbrido',
    ELECTRIC = 'electric;eléctrico;elettrico;elektro',
}

export enum FUEL_CODES {
    DIESEL = '03;04',
    BENZIN = '02',
    HYBRID = '01',
    PLUG_IN_HYBRID = '10',
    ELECTRIC = '05',
}

export enum ENVIRONMENT_TYPE {
    B2B = 'ec41_b2b',
    B2C = 'default',
    B2E = 'b2e',
}

export const OFFER_DEALER_DISCOUNT_TYPE = 'extra_dealer_discount';
export const OFFER_COMMENT_TYPE = 'offer_comment';

export enum DEAL_PRODUCT_TYPE {
    PARENT = 'parent',
    CHILD = 'child',
}

export const LEASYS_PROVIDER_TYPE = 'externalleasysfinancialgateway';

export const GOOGLE_MAP_API_KEY = environment.NEXT_PUBLIC_GOOGLE_MAP_API_KEY;
export const GOOGLE_MAP_DEFAULT_LAT = environment.NEXT_PUBLIC_GOOGLE_MAP_DEFAULT_LAT;
export const GOOGLE_MAP_DEFAULT_LNG = environment.NEXT_PUBLIC_GOOGLE_MAP_DEFAULT_LNG;
export const GOOGLE_MAP_DEFAULT_ZOOM = environment.NEXT_PUBLIC_GOOGLE_MAP_DEFAULT_ZOOM;
export const GOOGLE_MAP_MAX_ZOOM = environment.NEXT_PUBLIC_GOOGLE_MAP_MAX_ZOOM;
export const GOOGLE_MAP_SEARCH_LIMIT = environment.NEXT_PUBLIC_GOOGLE_MAP_SEARCH_LIMIT;
export const GOOGLE_RESTRICTED_COUNTRY = environment.NEXT_PUBLIC_GOOGLE_RESTRICTED_COUNTRY;

export const POSTHOG_DOMAIN = environment.NEXT_PUBLIC_POSTHOG_DOMAIN;
export const POSTHOG_API_KEY = environment.NEXT_PUBLIC_POSTHOG_API_KEY;

export const DEFAULT_DISTANCE_OPTIONS_STEP = parseInt(environment.NEXT_PUBLIC_DEFAULT_DISTANCE_OPTIONS_STEP);
export const DISTANCE_OPTIONS = environment.NEXT_PUBLIC_DISTANCE_OPTIONS;

export const B2B_BRANDID_REGISTER_URL_SUFFIX = environment.NEXT_PUBLIC_B2B_BRANDID_REGISTER_URL_SUFFIX;
export const B2B_URL = environment.NEXT_PUBLIC_URL_B2B;
export const B2C_URL = environment.NEXT_PUBLIC_URL_B2C;
export const CONTEXT = environment.NEXT_PUBLIC_CONTEXT ?? ENVIRONMENT_TYPE.B2C;

export const IS_B2B = CONTEXT === ENVIRONMENT_TYPE.B2B;
export const IS_B2C = CONTEXT === ENVIRONMENT_TYPE.B2C;

export const B2B_FILEDS: B2B_FIELD[] =
    typeof environment.NEXT_PUBLIC_B2B_FIELDS === 'string'
        ? JSON.parse(environment.NEXT_PUBLIC_B2B_FIELDS)
        : environment.NEXT_PUBLIC_B2B_FIELDS || [
              B2B_FIELD.COMPANY_NAME,
              B2B_FIELD.COMPANY_NUMBER,
              B2B_FIELD.COMPANY_VAT,
          ];

export const MODEL_UNIQUENESS: string[] =
    typeof environment.NEXT_PUBLIC_MODEL_UNIQUENESS === 'string'
        ? JSON.parse(environment.NEXT_PUBLIC_MODEL_UNIQUENESS)
        : environment.NEXT_PUBLIC_MODEL_UNIQUENESS || [
              'VP1PK001', // Expert Combi
              'VP1PK900', // Rifter
              'VP1PP100', // 2008
              'VP11PP20', // 208
              'VP1PP7E0', // 5008
              'VP1PP8E0', // 3008
              'VP1PP6CB', // Nouvelle 408
              'VPAC0020', //  Spacetourer business
              'VPAC0025', // Grand C4 Spacetourer
              'VPAC0008', // C4 Cactus Berline
              'VPAC0005', // C3 Berline
              'VPAC0028', // C5 Aircross hybrid
              '00001568', // UK - C3 Aircross SUV
              '00001601', // UK - Grand C4 Spacetourer
              '00000163', // UK - C3 New
              '0V001617', // UK - C5 Aircross SUV
              'VP1PR8BW', // 508 PSE,
              'VP1PR8SW', // 508 PSE SW
              'VP1PP101', // 2008
              'VP1PPD01', // 3008
              'VP1PK003', // Expert
              'VP1PK901', // Rifter
              'VP1PK002', // Traveller
              'VP11PP21', // 208
              'VPAC0023', // C3 Aircross
              'VPAC0027', // C5 Aircross
              'VPAC0029', // C4 X
              'VPAC0031', // C4
              'VPAC0032', // C4 Business
              'VPAC0037', // C5 X
              'VPAC0101', // eC3
              'VPAC0102', // C3
              'VPAC0103', // C4 X Business
              'VPAC0151', // Jumpy
          ];
export const ParametrizedPathRegExp = /\?(.*)/;

export const NON_BREAKING_SPACE = '\xa0';

export const FEATURE_SWITCHES_COOKIE_NAME = 'FEATURE_SWITCHES';

export const MM_HEADERS = Object.entries({
    'x-language': i18n.language || environment.NEXT_PUBLIC_LANGUAGE,
    'x-market': environment.NEXT_PUBLIC_MARKET,
    ...(environment.NEXT_PUBLIC_CHANNELS ? { 'x-channel': environment.NEXT_PUBLIC_CHANNELS } : {}),
    'x-context': CONTEXT,
    'x-brand-code': environment.NEXT_PUBLIC_BRAND,
    'x-env-name': environment.NEXT_PUBLIC_ENV_NAME,
})
    .filter(([key, value]) => value)
    .reduce((obj: any, [key, value]) => {
        obj[key] = value;
        return obj;
    }, {});

export const MULTI_LANGUAGES = environment.NEXT_PUBLIC_FEATURE_SWITCH_ALLOWED_LANGUAGES?.split(';') || [];
export const MULTI_LANGUAGES_ENABLED = MULTI_LANGUAGES.length > 0;

export const BR_DISABLE_PAGE_REDIRECT_URL = environment.NEXT_PUBLIC_BR_DISABLE_PAGE_REDIRECT_URL;

export const STOCK_REDICT_URL_FROM_CONFIG_PAGE = environment.NEXT_PUBLIC_STOCK_REDICT_URL_FROM_CONFIG_PAGE;

export const PROMO_CODE_KEY = `PROMO_CODE_${BRAND}_${MARKET}_${LANGUAGE}`;
// if u are planning to change LANGUAGE_KEY please change the format in server.js too (getLanguageCookie)
export const LANGUAGE_KEY = `LANGUAGE_${MARKET}_${environment.NEXT_PUBLIC_ENV_NAME}_${BRAND}`;

export const NEED_HAND_SETTING_KEY = 'need_a_hand_hp';

export const isMarketGB = MARKET === MARKETS.GB;
export const isMarketES = MARKET === MARKETS.ES;
export const isMarketIT = MARKET === MARKETS.IT;
export const isMarketDE = MARKET === MARKETS.DE;
export const isMarketFR = MARKET === MARKETS.FR;
export const isMarketBE = MARKET === MARKETS.BE;
export const isMarketPT = MARKET === MARKETS.PT;

export const TAX_PERCENTAGE = environment.NEXT_PUBLIC_TAX_PERCENTAGE || 0;

export const isProd = environment.NEXT_PUBLIC_ENV_NAME === 'green' || environment.NEXT_PUBLIC_ENV_NAME === 'blue';

export const DEFAULT_CACHE_MAX_AGE = environment.NEXT_PUBLIC_DEFAULT_CACHE_MAX_AGE || 300;

export const isVauxhall = isBrand(BRAND_OV) && isMarketGB;

export enum FILTER_CATEGORY {
    BUDGET = 'budget',
    FUEL = 'fuel',
    GEARBOX = 'gearbox',
    PAYMENT_JOURNEY_TYPE = 'paymentJourneyType',
    PRICES = 'prices',
    ENERGIES = 'energies',
    GEARBOX_TYPES = 'gearboxTypes',
    GRADES = 'grades',
    ENGINES = 'engines',
    COLOR_GROUPS = 'colorGroups',
    BODY_STYLES = 'bodyStyles',
    TRANSMISSION_TYPES = 'transmissionTypes',
    FINITION = 'finition',
    MOTORIZATION = 'motorization',
    COLOR = 'color',
    LOCATION = 'location',
}

export enum ModalVersion {
    v1 = 'v1',
    v2 = 'v2',
}

export enum FINANCE_DETAIL_VIEW {
    LINK = 'link',
    ICON = 'icon',
    HIDDEN = 'hidden',
}

export const config = {
    BRAND,
    MARKET,
    LANGUAGE,
    BACKEND_URL,
};

export const SETTINGS_SYNCHRONIZE_MINUTES = 5;

export const ENTER_KEY_CODE = 13;

export const ESC_KEY_CODE = 27;

export const RANGE_STEP = 100;
