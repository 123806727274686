import { IDealExtraFields } from './../../redux/user/user.duck.interface';
import { BRAND, LANGUAGE, LEASYS_PROVIDER_TYPE, MARKET, SESSION_REQUEST_HEADER_NAME } from '../../constants/main';
import { TBudgetType, STOCK } from '../core/sdk/constants/sdkFields';
import { IDeal, ISetConfigurationExtra, ISetConfigurationResponse } from './deal.types';
import coreApiService from '../core/coreApi.service';
import { getDefaultHeaders } from '../apiLayer/apiLayer.utils';
import { captureException } from '@utils/sentry.utils';
import { FINANCE } from '../filters/filters';
import { CarJourneyType } from '../../services';
import { IDealerFee } from '@components/DealerFees/DealerFeesTemplate';
import { AxiosResponse } from 'axios';
import { IConfigurableCar } from '../../interfaces/Car';
import { IFinanceQuote } from '../calculateSummary/calculateSummary.types';
import { isBoolean } from 'lodash';
import { IConfiguratorOption } from '../../redux/configurator/configurator.duck.interface';

const GET_CHECKOUT_REDIRECT_URL: string = 'digital-api/api/deals/get-redirect-url';
const CREATE_ORDER = 'digital-api/api/orders';
const SAVE_USER = 'digital-api/api/checkout/';
const UPDATE_DEAL_URL: string = 'digital-api/api/deals/update-deal';
const UPDATE_DEAL_URL_AGENT_PORTAL: string = 'digital-api/api/agent-portal/update-deal';
const APPLY_SCRAPPAGE: string = 'digital-api/api/deal/apply-scrappage-or-srotovne';
const APPLY_PROMO_CODE: string = 'digital-api/api/apply-promo-code';
const FINISH_LEASYS_JOURNEY: string = 'digital-api/api/deal/finish-leasys-journey';
const APPLY_PROMO_CODE_AGENT_PORTAL: string = 'digital-api/api/agent-portal/apply-promo-code';
const UPDATE_CUSTOM_PX_STATUS: string = 'digital-api/api/deals/update-px';
const CURRENT_DEAL_URL: string = 'digital-api/api/current/deal';
const SPC_BASE_PATH: string = 'spc-api/api/v1';

interface IDealUpdateBody {
    status?: string;
    dealerFees?: IDealerFee[];
    accessories?: string[];
    extra_field?: Partial<IDealExtraFields>;
    saved?: boolean;
    postalCode?: string;
    selectedDealerId?: string;
    selectedOptions?: IConfiguratorOption[];
    checkoutStep?: string;
    includeFees?: boolean;
    fetchDealerFees?: boolean;
}

interface IDealUpdateAgentPortalBody {
    reservationFee?: number;
    depositType?: string;
    agent_discount?: {
        type: string;
        amount: number;
    };
    px?: {
        makeName: string;
        modelName: string;
        vin: string;
        overallMilleage: number;
        prices: {
            dealerValuation: number;
            dealerBonusAmount: number;
        };
        isActive: boolean;
    };
    fees?: {}[];
    comment?: string;
    dealerRegistrationFee?: number;
}

/** API service for working with Deal with sequential request enforcement */
export class DealService {
    /** Promise-chain of actions that affect deal, awaiting this lock makes sure there is no ongoing deal action */
    static lock: Promise<void> = Promise.resolve();
    /** Pushes promise onto the lock so other deal actions await it */
    public static pushLock<T>(promise: Promise<T>): Promise<void> {
        // creating new promise breaks up the chain (old promises in front can be garbage collected)
        // so we don't have memory leak after many promises are chained
        this.lock = new Promise<void>((resolve) => this.lock.then(() => promise).finally(resolve));
        return this.lock;
    }
    public static async setConfiguration(
        token: string | null | undefined,
        configuration: string,
        journeyType: TBudgetType,
        carJourney: CarJourneyType,
        extra: ISetConfigurationExtra = {},
        reuseDeal?: boolean | null,
        hasLeasysSelected?: boolean
    ): Promise<ISetConfigurationResponse> {
        const carJourneyPath = carJourney === STOCK ? STOCK + '/' : '';

        await this.lock;
        const request = coreApiService.post(
            `${SPC_BASE_PATH}/${MARKET}/${LANGUAGE}/${BRAND}/configuration/${carJourneyPath}${configuration}/${journeyType}`,
            {
                extra,
                ...(hasLeasysSelected && { providerType: LEASYS_PROVIDER_TYPE }),
                ...(isBoolean(reuseDeal) && { reuseDeal }),
            },
            this.getCommonHeaders(token),
            { withCredentials: true }
        );
        this.pushLock(request);
        const response = await request;

        const { deal, financeQuote } = response?.data?.data;

        if (!deal) {
            captureException(
                `Failed to create deal with configuration ${configuration} and ${journeyType} journey type!`
            );
        }

        if (!financeQuote?.token && journeyType === FINANCE) {
            captureException(`Finance quote is missing in created deal ${deal?.token}!`);
        }

        return response;
    }
    public static async getCheckoutRedirectUrl(
        journeyType: TBudgetType,
        finance_flow: number,
        token?: string,
        culture?: string
    ) {
        try {
            await this.lock;
            const request = coreApiService.get(
                GET_CHECKOUT_REDIRECT_URL,
                { finance_flow, culture },
                this.getCommonHeaders(token)
            );
            this.pushLock(request);
            return request;
        } catch {
            throw new Error(`Invalid type ${journeyType}`);
        }
    }
    public static async createOrder(token?: string) {
        await this.lock;
        const request = coreApiService.post(CREATE_ORDER, {}, this.getCommonHeaders(token));
        this.pushLock(request);
        return request;
    }
    public static async saveUser(dealId: string, user: any) {
        await this.lock;
        const request = coreApiService.post(
            SAVE_USER,
            {
                dealId,
                customer: user,
            },
            this.getCommonHeaders()
        );
        this.pushLock(request);
        return request;
    }
    public static async updatePxStatus(isActive: boolean, token?: string) {
        await this.lock;
        const request = coreApiService.post(`${UPDATE_CUSTOM_PX_STATUS}`, { isActive }, this.getCommonHeaders(token));
        this.pushLock(request);
        return request;
    }
    public static async finishLeasysJourney(token: string, dealId: string, data: {}) {
        await this.lock;
        const request = coreApiService.post(
            FINISH_LEASYS_JOURNEY,
            {
                dealId,
                ...data,
            },
            this.getCommonHeaders(token)
        );
        this.pushLock(request);
        return request;
    }
    public static async updateDealAgentPortal(dealUpdate: IDealUpdateAgentPortalBody, token?: string) {
        await this.lock;
        const request = coreApiService.post(
            `${UPDATE_DEAL_URL_AGENT_PORTAL}`,
            dealUpdate,
            this.getCommonHeaders(token)
        );
        this.pushLock(request);
        return request;
    }
    public static async updateDeal(dealUpdate: IDealUpdateBody, token?: string, shouldResponseData: boolean = true) {
        await this.lock;
        const request = coreApiService.put(
            `${UPDATE_DEAL_URL}?sendData=${shouldResponseData}`,
            dealUpdate,
            this.getCommonHeaders(token)
        );
        this.pushLock(request);
        return request;
    }
    public static async activateDeal(dealId: string, token?: string, isRestoreSession?: boolean) {
        await this.lock;
        const request = coreApiService.patch(
            `digital-api/api/deals/${dealId}/activate`,
            {
                isRestoreSession,
            },
            this.getCommonHeaders(token)
        );
        this.pushLock(request);
        return request;
    }
    public static async deleteDeal(dealId: string, token?: string) {
        await this.lock;
        const request = coreApiService.delete(`digital-api/api/deals/${dealId}`, {}, this.getCommonHeaders(token));
        this.pushLock(request);
        return request;
    }
    public static async deleteDealDealer(token?: string) {
        await this.lock;
        const request = coreApiService.delete(`digital-api/api/deal/dealer`, {}, this.getCommonHeaders(token));
        this.pushLock(request);
        return request;
    }
    public static async applyScrappage<ProductConfigurationType = IConfigurableCar>(
        isWithScrappage: boolean,
        token?: string
    ): Promise<AxiosResponse<{ deal: IDeal<ProductConfigurationType>; financeQuote?: IFinanceQuote }>> {
        await this.lock;
        const request = coreApiService.post(
            APPLY_SCRAPPAGE,
            {
                isWithScrappage,
            },
            this.getCommonHeaders(token)
        );
        this.pushLock(request);
        return request;
    }
    public static saveDeal(token: string, extraFields: Partial<IDealExtraFields>) {
        return this.updateDeal(
            {
                saved: true,
                extra_field: extraFields,
            },
            token
        );
    }
    public static async applyPromoCode<ProductConfigurationType = IConfigurableCar>(
        code: string,
        token: string,
        isCheckRequest: boolean = false
    ): Promise<
        AxiosResponse<{
            success: boolean;
            messageId?: string;
            deal: IDeal<ProductConfigurationType>;
            financeQuote?: IFinanceQuote;
        }>
    > {
        await this.lock;
        const request = coreApiService.post(
            APPLY_PROMO_CODE,
            { promoCode: code, isCheckRequest },
            this.getCommonHeaders(token)
        );
        this.pushLock(request);
        return request;
    }
    public static async removePromoCode<ProductConfigurationType = IConfigurableCar>(
        code: string,
        token: string
    ): Promise<
        AxiosResponse<{ success: boolean; deal: IDeal<ProductConfigurationType>; financeQuote?: IFinanceQuote }>
    > {
        await this.lock;
        const request = coreApiService.delete(APPLY_PROMO_CODE, { promoCode: code }, this.getCommonHeaders(token));
        this.pushLock(request);
        return request;
    }
    public static updateDealStatus(
        status: string = 'basket',
        token: string,
        extraFields: Partial<IDealExtraFields> = {},
        additionalData: Partial<IDealUpdateBody> = {}
    ) {
        return this.updateDeal(
            {
                status,
                extra_field: extraFields,
                ...additionalData,
            },
            token
        );
    }
    public static async applyPromoCodeAgentPortal<ProductConfigurationType = IConfigurableCar>(
        code: string,
        token: string
    ): Promise<
        AxiosResponse<{
            success: boolean;
            messageId?: string;
            deal: IDeal<ProductConfigurationType>;
            financeQuote?: IFinanceQuote;
        }>
    > {
        await this.lock;
        const request = coreApiService.post(APPLY_PROMO_CODE_AGENT_PORTAL, { code }, this.getCommonHeaders(token));
        this.pushLock(request);
        return request;
    }
    /**
     * Gets current deal attached to session.
     * Can await updates that are ongoing before requesting deal to make sure the data is the latest from backend.
     * @param {boolean} awaitUpdates if should wait on ongoing updates before requesting deal, defaulting to true
     * */
    public static async currentDeal<ProductConfigurationType = IConfigurableCar>(
        token: string,
        isOrder: boolean = false,
        awaitUpdates: boolean = true
    ): Promise<AxiosResponse<IDeal<ProductConfigurationType>>> {
        const headers = {
            [SESSION_REQUEST_HEADER_NAME]: token || '',
        };
        if (awaitUpdates) {
            await this.lock;
        }
        const response = await coreApiService.get(CURRENT_DEAL_URL, { ordered: isOrder }, headers);

        // Add pricesV2 and regularPaymentConditions to extraFields mapping for SOL
        response.data.fullProductConfiguration.extraFields = {
            pricesV2: response.data?.fullProductConfiguration?.pricesV2,
            regularPaymentConditions: response.data?.fullProductConfiguration?.regularPaymentConditions,
        };

        return response;
    }
    private static getCommonHeaders(token?: string | null) {
        const headers = {
            ...getDefaultHeaders(),
        };
        if (token) {
            headers[SESSION_REQUEST_HEADER_NAME] = token;
        }
        return headers;
    }
}

export default DealService;
