import { Icons } from '..';
import car from './icons/car.svg';
import carsStore from './icons/carsStore.svg';
import securePayment from './icons/securePayment.svg';
import fuel from './icons/fuel.svg';
import manual from './icons/manual.svg';
import download from './icons/download.svg';
import cart from './icons/cart.svg';
import info from './icons/info.svg';
import infoCircle from './icons/infoCircle.svg';
import chevronUp from './icons/chevronUp.svg';
import chevronDown from './icons/chevronDown.svg';
import chevronRight from './icons/chevronRight.svg';
import chevronRightThin from './icons/ChevronRightThin.svg';
import question from './icons/question.svg';
import trash from './icons/trash.svg';
import request from './icons/request.svg';
import menu from './icons/menu.svg';
import drive from './icons/drive.svg';
import phone from './icons/phone.svg';
import profile from './icons/profile.svg';
import profileCall from './icons/profileCall.svg';
import phoneV2 from './icons/phoneV2.svg';
import plus from './icons/plus.svg';
import minus from './icons/minus.svg';
import settings from './icons/settings.svg';
import arrowUp from './icons/arrowUp.svg';
import clock from './icons/iconClock.svg';
import infoAlternative from './icons/infoAlternative.svg';
import information from './icons/information.svg';
import customize from './icons/customize.svg';
import zoomIn from './icons/zoomIn.svg';
import squarePlus from './icons/squarePlus.svg';
import headset from './icons/headset.svg';
import cancel from './icons/cancel.svg';
import roundInfo from './icons/roundInfo.svg';
import emissionA from './icons/emissionA.svg';
import myAccount from './icons/myAccount.svg';
import MapPoint from './icons/mapPoint.svg';
import Check from './icons/check.svg';
import target from './icons/target.svg';
import storeCar from './icons/storeCar.svg';
import alert from './icons/alert.svg';
import alertOutlined from './icons/alertOutlined.svg';
import calculator from './icons/calculator.svg';
import calculatorDS from './icons/calculatorDS.svg';
import chevronLeftOutlined from './icons/chevronLeftOutlined.svg';
import chevronRightOutlined from './icons/chevronRightOutlined.svg';
import reinsuranceCalendar from './icons/calendar.svg';
import shareConfigurationModalIcon from './icons/shareConfigurationModalIcon.svg';
import search from './icons/search.svg';
import interior from './icons/interior.svg';
import exterior from './icons/exterior.svg';
import interiorBold from './icons/interiorBold.svg';
import exteriorBold from './icons/exteriorBold.svg';
import chevronLeftV2 from './icons/chevronLeftV2.svg';
import edit from './icons/edit.svg';

export const iconsShared = (name: Icons) => {
    switch (name) {
        case Icons.ShareConfigurationModalIcon:
            return shareConfigurationModalIcon;
        case Icons.ReinsuranceCalendar:
            return reinsuranceCalendar;
        case Icons.Car:
            return car;
        case Icons.InteriorV2:
            return interior;
        case Icons.ExteriorV2:
            return exterior;
        case Icons.Menu:
            return menu;
        case Icons.InteriorBold:
            return interiorBold;
        case Icons.ExteriorBold:
            return exteriorBold;
        case Icons.Profile:
            return profile;
        case Icons.ProfileCall:
            return profileCall;
        case Icons.Alert:
            return alert;
        case Icons.AlertOutlined:
            return alertOutlined;
        case Icons.Trash:
            return trash;
        case Icons.Calculator:
            return calculator;
        case Icons.CalculatorDS:
            return calculatorDS;
        case Icons.CarStore:
            return carsStore;
        case Icons.SecurePayment:
            return securePayment;
        case Icons.Fuel:
            return fuel;
        case Icons.Headset:
            return headset;
        case Icons.Question:
            return question;
        case Icons.Drive:
            return drive;
        case Icons.Request:
            return request;
        case Icons.Manual:
            return manual;
        case Icons.Download:
            return download;
        case Icons.Cart:
            return cart;
        case Icons.Info:
            return info;
        case Icons.InfoCircle:
            return infoCircle;
        case Icons.Information:
            return information;
        case Icons.ChevronUp:
            return chevronUp;
        case Icons.ChevronDown:
            return chevronDown;
        case Icons.ChevronRight:
            return chevronRight;
        case Icons.ChevronRightThin:
            return chevronRightThin;
        case Icons.Phone:
            return phone;
        case Icons.PhoneV2:
            return phoneV2;
        case Icons.Plus:
            return plus;
        case Icons.Minus:
            return minus;
        case Icons.Settings:
            return settings;
        case Icons.ArrowUp:
            return arrowUp;
        case Icons.Clock:
            return clock;
        case Icons.InfoAlternative:
            return infoAlternative;
        case Icons.Customize:
            return customize;
        case Icons.ZoomIn:
            return zoomIn;
        case Icons.SquarePlus:
            return squarePlus;
        case Icons.Cancel:
            return cancel;
        case Icons.RoundInfo:
            return roundInfo;
        case Icons.EmissionA:
            return emissionA;
        case Icons.MyAccount:
            return myAccount;
        case Icons.MapPoint:
            return MapPoint;
        case Icons.Check:
            return Check;
        case Icons.Target:
            return target;
        case Icons.StoreCar:
            return storeCar;
        case Icons.Search:
            return search;
        case Icons.chevronLeftV2:
            return chevronLeftV2;
        case Icons.edit:
            return edit;
        case Icons.chevronLeftOutlined:
            return chevronLeftOutlined;
        case Icons.chevronRightOutlined:
            return chevronRightOutlined;
        default:
            return '';
    }
};
